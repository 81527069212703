import React from 'react';
import styled from 'styled-components';
import ContentBar from '../../_atoms/ContentBar';
import LanguageContext from '.././../_utilities/LanguageContext';
import Breakpoints from '../../_utilities/Breakpoints';

const NavLink = styled.a`
	font-family: 'Lato', sans-serif;
	display: block;
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.9px;
	text-transform: uppercase;
	text-decoration: none;

	&:first-child {
		margin-right: 4%;
	}

	&:hover {
		color: #c5dff8;
	}

	@media (max-width: ${Breakpoints.custom560}) {
		font-size: 15.52px;
		line-height: 18px;
		letter-spacing: 0.9px;
	}

	@media (max-width: ${Breakpoints.mobileL}) {
		&:first-child {
			margin-right: 20%;
		}
	}
`;

const Container = styled(ContentBar)`
	padding: 0;
	height: 92px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	@media (max-width: 1114px) {
	}
	@media (max-width: ${Breakpoints.custom560}) {
	}
`;

function InfoBar() {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				const { trends, prevention } = getText('infobar');
				return (
					<Container>
						<NavLink
							href='https://www.waynecounty.com/covid19dashboard/'
							title={trends.title}
						>
							{trends.label}
						</NavLink>
						<NavLink
							href='https://www.waynecounty.com/covid19/prevention.aspx'
							title={prevention.title}
						>
							{prevention.label}
						</NavLink>
					</Container>
				);
			}}
		</LanguageContext.Consumer>
	);
}

export default InfoBar;
