import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ContentBar from '../../_atoms/ContentBar';
import LanguageContext from '../../_utilities/LanguageContext';

import WashIcon from './wash-your-hands.png';
import SixFeetIcon from './six-feet-apart.png';
import WearMaskIcon from './wear-a-mask.png';

import Icon from '../../_atoms/Icon';
import Breakpoints from '../../_utilities/Breakpoints';

const Top = styled(ContentBar)`
	height: auto;
	min-height: ${(props) => props.height}px;
	font-weight: 900;
	color: #ffffff;
	justify-content: space-between;
	flex-wrap: wrap;
	p {
		letter-spacing: 1px;
		font-size: 22px;
	}

	> :first-child {
		width: 100%;
		text-align: center;
	}

	@media (max-width: ${Breakpoints.laptop}) {
		flex-wrap: wrap;
		padding-bottom: 16px;
		> p {
			width: 100%;
		}
	}

	@media (max-width: ${Breakpoints.custom560}) {
		display: block;
		text-align: center;
		padding: 23px 10px 19px;
		box-sizing: border-box;
		> p {
			width: 100%;
			font-size: 15.53px;
			line-height: 19.84px;
			padding: 0;
			margin-top: 0;
		}
	}
`;

const InsructionContainer = styled.div`
	color: #ffffff;
	text-transform: uppercase;
	font-size: 21px;
	letter-spacing: 1.2px;
	display: flex;
	align-items: center;
	img {
		margin-right: 20px;
	}
	@media (max-width: ${Breakpoints.custom700}) {
		p {
			font-size: 15.53px;
			line-height: 18px;
			letter-spacing: 0.9px;
		}

		img {
			width: 40px;
			height: auto;
		}
	}

	@media (max-width: ${Breakpoints.custom560}) {
		justify-content: center;
		padding-bottom: 0;
		&:not(:last-child) {
			margin-bottom: 23px;
		}
		img {
			margin-right: 12px;
			width: 50px;
			height: auto;
		}

		&:nth-of-type(2) img {
			width: 54px;
		}
	}
`;
function InstructionIcon(props) {
	return (
		<InsructionContainer>
			<img src={props.icon} alt={props.iconalt} />
			<p>{props.label}</p>
		</InsructionContainer>
	);
}

const Bottom = styled(ContentBar)`
	height: auto;
	min-height: ${(props) => props.height}px;
	color: #ffffff;
	flex-wrap: wrap;
	align-content: center;

	.copyright {
		order: 3;
		width: 100%;
		letter-spacing: 0.6px;
		font-size: 10px;
		font-weight: 700;
		margin: 6px 0 0 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.social {
		display: flex;
		align-items: center;
		margin-right: auto;
		order: 1;

		h1 {
			text-transform: uppercase;
			font-weight: 900;
			font-size: 18px;
			letter-spacing: 1px;
			display: inline-block;
			margin: 0 12px 0 0;
			margin-right: 12px;
		}
		a:not(:last-of-type) {
			margin-right: 12px;
		}
	}

	.external-resources {
		order: 2;
		display: flex;
		align-items: center;
		a {
			text-decoration: none;
			color: #69758c;
			font-size: 16px;
			letter-spacing: 0.6px;
			font-weight: 400;
			padding: 0 20px;
		}
	}

	@media (max-width: 810px) {
		.social {
			/* width: 100%; */
		}
		.external-resources {
			width: 100%;
			a:first-child {
				padding-left: 0;
			}
		}
	}

	@media (max-width: 516px) {
		box-sizing: border-box;
		padding: 40px 25px;
		.social {
			width: 100%;
			margin: 0;
			h1 {
				font-size: 15.53px;
				line-height: 18px;
				margin-right: auto;
			}
		}

		.copyright {
			order: 2;
			font-size: 10px;
			line-height: 18px;
			letter-spacing: 0.6px;
			text-align: center;
			margin-top: 21px;
			margin-bottom: 28px;
		}

		.external-resources {
			order: 3;
			flex-wrap: wrap;
			> * {
				width: 100%;
				text-align: center;
				&:not(:last-child) {
					margin-bottom: 18px;
				}
			}
		}
	}

	@media (max-width: ${Breakpoints.mobileL}) {
		.copyright {
			text-align: left;
		}
		.external-resources {
			a {
				padding-left: 0;
				padding-right: 0;
			}
			> * {
				text-align: left;
			}
		}
	}
`;

function Footer(props) {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				const {
					thanks,
					space,
					mask,
					wash,
					social,
					external,
					copyright,
				} = getText('footer');

				const { facebook, twitter, chat } = getText('icons');

				return (
					<footer>
						<Top height={112} bgColor='#69758C'>
							<p>{thanks}</p>
							<InstructionIcon
								icon={SixFeetIcon}
								iconalt={space.icon.alt}
								label={space.label}
							/>
							<InstructionIcon
								icon={WearMaskIcon}
								iconalt={mask.icon.alt}
								label={mask.label}
							/>
							<InstructionIcon
								icon={WashIcon}
								iconalt={wash.icon.alt}
								label={wash.label}
							/>
						</Top>
						<Bottom height={115}>
							<div className='social'>
								<h1>{social.header}</h1>
								<a
									href='https://www.facebook.com/waynecountymi/'
									title={facebook.title}
								>
									<Icon type='facebook' />
								</a>
								<a
									href='https://twitter.com/waynecountymi'
									title={twitter.title}
								>
									<Icon type='twitter' />
								</a>
								<a
									href='https://www.waynecounty.com/county/contact.aspx'
									title={chat.title}
								>
									<Icon type='chat' />
								</a>
							</div>
							<div className='external-resources'>
								<a
									href='https://www.waynecounty.com/county/transparency-in-government.aspx'
									title={external.gov.title}
								>
									{external.gov.label}
								</a>
								<a
									href='https://www.waynecounty.com/county/news-and-events.aspx'
									title={external.news.title}
								>
									{external.news.label}
								</a>
								<a
									href='https://www.waynecounty.com/county/aboutwc.aspx'
									title={external.info.title}
								>
									{external.info.label}
								</a>
							</div>
							<p className='copyright'>{ReactHtmlParser(copyright)}</p>
						</Bottom>
					</footer>
				);
			}}
		</LanguageContext.Consumer>
	);
}

export default Footer;
