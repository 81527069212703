import React from 'react';
import styled from 'styled-components';
import Context from '../../_utilities/LanguageContext';
import Parse from 'react-html-parser';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled.div`
	width: 361px;
	height: 96px;
	display: flex;
	align-items: center;

	h2 {
		font-size: 38px;
		letter-spacing: 2.1px;
		color: #1f2a40;
		margin: 0;
		text-transform: uppercase;
		font-weight: 400;
		line-height: 53px;

		span {
			/* margin-top: 32px; */
			font-size: 44px;
			display: block;
			letter-spacing: 2.5px;
			font-weight: 900;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		width: 100%;
		height: auto;
		text-align: center;
		margin-bottom: 22px;
		h2 {
			width: 100%;
			font-size: 19.15px;
			letter-spacing: 1.3px;
			line-height: 27px;
			span {
				margin: 0;
				letter-spacing: 1.1px;
				font-size: 22.23px;
			}
		}
	}
`;

function CopyLockup(props) {
	return (
		<Context.Consumer>
			{({ getText }) => {
				let copy = getText(props.modelkey);
				return (
					<Container>
						<h2>{Parse(copy)}</h2>
					</Container>
				);
			}}
		</Context.Consumer>
	);
}

export default CopyLockup;
