import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExtraPropTypes from 'react-extra-prop-types';

const Container = styled.div`
	background: ${(props) => props.bgColor};
	height: ${(props) => props.height}px;
	padding: 0 45px;
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justify};
	box-sizing: border-box;
	width: 100%;

	@media (max-width: 320px) {
		padding: 0 10px;
	}
`;

function ContentBar(props) {
	return <Container {...props}>{props.children}</Container>;
}

ContentBar.propTypes = {
	bgColor: ExtraPropTypes.color,
	height: PropTypes.number,
	justify: PropTypes.string,
};

ContentBar.defaultProps = {
	bgColor: '#1f2a40',
	height: 56,
	justify: 'flex-start',
};

export default ContentBar;
