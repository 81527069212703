import React from 'react';
import styled from 'styled-components';
import ContentBar from '../../_atoms/ContentBar';
import Icon from '../../_atoms/Icon';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled(ContentBar)`
	img {
		width: auto;
		height: 102px;
	}
	@media (max-width: ${Breakpoints.custom560}) {
		height: 84px;
		padding: 0 10px;
		img {
			height: 64px;
		}
	}
`;

function BrandBar() {
	return (
		<Container height={143}>
			<Icon type='brand' />
		</Container>
	);
}

export default BrandBar;
