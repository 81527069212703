import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import LanguageContext from '../../_utilities/LanguageContext';
import PropTypes from 'prop-types';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled.div`
	position: relative;
	figure {
		margin: 0;
		min-height: 222px;
		overflow: hidden;
		position: relative;
	}
	img {
		vertical-align: bottom;
		width: auto;
		height: 100%;
		float: right;
	}
	div {
		position: absolute;
		z-index: 5;
		color: #ffffff;
		left: 4%;
		top: 50%;
		transform: translateY(-50%);
	}

	h2 {
		font-size: 38px;
		letter-spacing: 2.1px;
		color: #ffffff;
		margin: 0;
		text-transform: uppercase;
		font-weight: 400;

		span {
			margin-top: 32px;
			font-size: 44px;
			display: block;
			letter-spacing: 2.5px;
			font-weight: 900;
		}
	}

	@media (max-width: ${Breakpoints.laptop}) {
		img {
			position: relative;
			right: -200px;
		}
	}

	@media (max-width: ${Breakpoints.tablet}) {
		img {
			position: relative;
			right: -250px;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		figure {
			height: 222px;
			position: relative;
			overflow: hidden;
		}
		img {
			height: 100%;
			width: auto;
		}

		div {
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		h2 {
			text-align: center;
			font-size: 19.15px;
			line-height: 27px;
			letter-spacing: 1.3px;

			span {
				font-size: 22.23px;
				margin-top: 0;
				letter-spacing: 1.1px;
			}
		}
	}
`;

function CopyOverImage(props) {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				const { header, image } = getText(props.modelkey);
				return (
					<Container>
						<div>
							<h2>{ReactHtmlParser(header)}</h2>
						</div>
						<figure>
							<img
								src={process.env.PUBLIC_URL + '/img/' + props.image}
								alt={image.alt}
							/>
						</figure>
					</Container>
				);
			}}
		</LanguageContext.Consumer>
	);
}

CopyOverImage.propTypes = {
	/**
	 * this is the filename of an image in the public/img directory
	 */
	image: PropTypes.string.isRequired,
	/**
	 * the key to in the Language model
	 * the model should follow the format of {header: "", image: {alt: ""}}
	 */
	modelkey: PropTypes.string.isRequired,
};

export default CopyOverImage;
