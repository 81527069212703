const LanguageModel = {
	en: {
		page: {
			title: 'Wayne County, Michigan, COVID-19 Resources',
			description:
				'Get valuable COVID-19 information, find free testing locations and learn about care, safety and contact tracing',
		},
		icons: {
			chat: {
				title: 'Wayne County Contact Information',
				icon: { alt: 'Chat bubble' },
			},
			phone: { alt: 'Phone icon with chat bubbles' },
			brand: {
				alt: 'Charter County of Wayne Michigan logo',
				title: 'Wayne County Health Department',
			},
			langAR: { alt: 'Click here to change your language to Arabic' },
			langBN: { alt: 'Click here to change your language to Bengali' },
			langES: { alt: 'Click here to change your language to Spanish' },
			langEN: { alt: 'Click here to change your language to English' },
			facebook: {
				title: 'Follow us on Facebook',
				icon: { alt: 'Facebook logo' },
			},
			twitter: { title: 'Follow us on Twitter', icon: { alt: 'Twitter logo' } },
		},
		hero: {
			head: 'SCHEDULE your <span>covid-19 vaccine</span> APPOINTMENT',
			copy:
				'All Wayne County residents age 12+ are eligible. Vaccines are safe, effective and free. <a href="https://www.waynecounty.com/covid19/vaccination.aspx">Learn&nbsp;More.</a>',
			image: {
				alt: 'Bandaid image with vaccine CTA number',
				title: 'Call for more information',
			},
		},
		infobar: {
			trends: {
				label: 'Covid-19 Trends',
				title: 'Click here to check out the latest COVID-19 trends',
			},
			prevention: {
				label: 'Prevention',
				title:
					'Click here to see what steps you can take to stop the spread of COVID-19',
			},
			questions: 'COVID-19 questions?',
			call:
				"Please call 2-1-1 or <a title='Call for more information' href='tel:17342877870'>(734) 287-7870</a> for more information.",
		},
		freeTestingLockup: 'Free Testing <span>is Available</span>',
		freeTests: {
			header: 'Free Drive-Thru Tests',
			body: 'Find <b>FREE</b> weekend testing events near you.',
			cta: {
				label: 'Free Tests',
				title: 'Click here to learn about free drive-thru COVID-19 tests',
			},
		},
		testingLocator: {
			header: 'Testing Site Locator',
			body: 'See all testing sites near you.',
			cta: {
				label: 'All Sites',
				title: 'Click here to see all testing sites near you',
			},
		},
		itspreads: {
			header: 'Covid-19 spreads easily.',
			subheader: 'Anyone can get it.',
			body:
				'<p>You can be exposed to COVID-19 from anyone—relatives, friends, coworkers or strangers. If you think you may have been exposed or feel sick, find out how to protect yourself and others.</p>',
			cta: {
				label: 'See Treatment, Care and Safety Information',
				title:
					'Click here to find information about treatment options and safety information',
			},
			image: { alt: 'Various people wearing masks' },
		},
		protect: {
			header: 'Help protect your family, friends and neighbors',
			body:
				'If you are contacted by a Wayne County contact tracer, please respond to the call. Your answers help keep others safe, and your identity is always protected.',
			cta: {
				label: 'Learn About Contact Tracing',
				title: 'Click here to learn about contact tracing',
			},
		},
		learn: {
			header: 'learn to stop <span>the spread</span>',
			image: { alt: 'Muslim mother and daughter looking at tablet' },
		},
		takesteps:
			'We all can take steps to protect each other. Wear a mask, keep a safe distance from other people and avoid large gatherings. <strong>This includes activities with family and friends who do not live in your home.</strong>',
		holiday: {
			header: 'HOLIDAY SAFETY',
			body:
				'With many celebrations coming up, see the latest safety guidelines.',
			cta: {
				label: 'Learn More',
				title:
					'Click here to learn more about how to celebrate the holidays safely',
			},
		},
		stayinformed: {
			header: 'STAY INFORMED',
			body:
				'Be a protector of your community. Keep up with the latest information on COVID-19 from the Wayne County Health Department.',
			cta: {
				label: 'View Dashboard',
				title: 'Click here to get the latest information on COVID-19',
			},
		},
		footer: {
			thanks: 'Thank you for helping us keep you safe!',
			space: {
				label: 'space out',
				icon: { alt: 'Icon of two people standing apart' },
			},
			mask: { label: 'mask on', icon: { alt: 'Mask icon' } },
			wash: { label: 'wash up', icon: { alt: 'Handwashing icon' } },
			external: {
				gov: {
					label: 'Transparency in Govt.',
					title: 'Transparency in Government',
				},
				news: { label: 'News and Events', title: 'Wayne County News & Events' },
				info: { label: 'Info', title: 'About Wayne County, Michigan' },
			},
			social: {
				header: 'Stay Connected',
			},
			copyright:
				'© 2021 Wayne County, Michigan, All Rights Reserved. <a title="View Wayne County, Michigan, Privacy Statement" href="https://www.waynecounty.com/county/privacy.aspx">Privacy Statement</a>, <a title="View Wayne County, Michigan, Legal Notices and Terms of Use" href="https://www.waynecounty.com/county/privacy.aspx">Legal Notices and Terms of Use</a>',
		},
	},
	es: {
		page: {
			title: 'Recursos sobre el COVID-19 del condado de Wayne, Michigan',
			description:
				'Obtenga información valiosa sobre el COVID-19, encuentre centros de pruebas gratuitas y obtenga información sobre atención, seguridad y rastreo de contactos',
		},
		icons: {
			chat: {
				title: 'Información de contacto del condado de Wayne',
				icon: { alt: 'Burbuja de chat' },
			},
			phone: { alt: 'Icono de teléfono con burbujas de chat ' },
			brand: {
				alt: 'Logotipo de Charter County of Wayne, Michigan',
				title: 'Departamento de Salud del condado de Wayne',
			},
			langAR: { alt: 'Haga clic aquí para cambiar su idioma a árabe' },
			langBN: { alt: 'Haga clic aquí para cambiar su idioma a bengalí' },
			langES: { alt: 'Haga clic aquí para cambiar su idioma a español' },
			langEN: {
				alt: 'Haga clic aquí para cambiar su idioma a inglés',
			},
			facebook: {
				title: 'Síganos en Facebook',
				icon: { alt: 'Logotipo de Facebook' },
			},
			twitter: {
				title: 'Síganos en Twitter',
				icon: { alt: 'Logotipo de Twitter' },
			},
		},
		hero: {
			head:
				'Reserve su turno para recibir la vacuna contra el COVID-19 ahora mismo.',
			copy:
				'Todos los residentes del condado de Wayne mayores de 12 años pueden registrarse. Las vacunas son seguras, eficaces y gratuitas. <a href="https://www.waynecounty.com/covid19/vaccination.aspx">Más información</a> ',
			image: {
				alt: 'Imagen de apósito con número de teléfono para la vacuna',
				title: 'Llame para obtener más información',
			},
		},
		infobar: {
			trends: {
				label: 'NOVEDADES SOBRE el COVID-19',
				title:
					'Haga clic aquí para consultar las últimas tendencias con respecto a el COVID-19',
			},
			prevention: {
				label: 'NOTICIAS PREVENCIÓN',
				title:
					'Haga clic aquí para ver qué medidas puede tomar para detener la propagación de el COVID-19',
			},
			questions: '',
			call: '',
		},
		freeTestingLockup: 'Pruebas de COVID-19 Gratuitas',
		freeTests: {
			header: 'PRUEBAS GRATUITAS DESDE SU AUTOMÓVIL',
			body: 'Descubra sitios de pruebas GRATUITAS cerca de su hogar.',
			cta: {
				label: 'Pruebas gratuitas',
				title:
					'Haga clic aquí para obtener información sobre las pruebas de COVID-19 desde el automóvil gratuitas',
			},
		},
		testingLocator: {
			header: 'BUSCADOR DE SITIOS DE PRUEBAS',
			body: 'Ver todos los sitios de pruebas de COVID-19 cerca de su hogar.',
			cta: {
				label: 'Todos los sitios de pruebas',
				title:
					'Haga clic aquí para ver todos los centros de pruebas más cercanos',
			},
		},
		itspreads: {
			header: 'el COVID-19 SE PROPAGA CON FACILIDAD.',
			subheader: 'CUALQUIERA PUEDE CONTRAERLA.',
			body:
				'Puede entrar en contacto con el COVID-19 a través de cualquier persona, ya sean amigos, parientes, compañeros de trabajo o desconocidos. Si cree que ha estado expuesto al virus o presenta síntomas, reciba toda la información necesaria para protegerse usted y para proteger a los demás.',
			cta: {
				label: 'Ver información sobre tratamiento, atención médica y seguridad',
				title:
					'Haga clic aquí para ver todos los centros de pruebas más cercanos',
			},
			image: { alt: 'Varias personas con mascarillas' },
		},
		protect: {
			header: 'PROTEJA A SU FAMILIA, AMIGOS Y VECINOS',
			body:
				'Si un trabajador de salud pública del condado de Wayne se comunica con usted, responda la llamada. Sus respuestas nos permitirán proteger a otras personas y siempre se resguardará su identidad.',
			cta: {
				label: 'Más información sobre el Rastreo de Contacto',
				title:
					'Haga clic aquí para obtener información sobre el rastreo de contactos',
			},
		},
		learn: {
			header: 'APRENDA A DETENER LA PROPAGACIÓN',
			image: { alt: 'Madre e hija musulmanas mirando una tableta' },
		},
		takesteps:
			'Todos podemos tomar medidas para protegernos mutuamente. Utilice cubreboca, mantenga una distancia prudente con los demás y evite las reuniones masivas. Esto incluye actividades familiares o con amigos que no conviven con usted. ',
		holiday: {
			header: 'SEGURIDAD EN LAS FESTIVIDADES',
			body:
				'Quedan muchas festividades por celebrar, por lo que le recomendamos leer las medidas de seguridad más recientes.',
			cta: {
				label: 'Más información',
				title:
					'Haga clic aquí para obtener más información sobre cómo celebrar las fiestas de manera segura',
			},
		},
		stayinformed: {
			header: 'MANTÉNGASE INFORMADO',
			body:
				'Proteja a su comunidad. Reciba las últimas noticias sobre el COVID-19 a través del Departamento de Salud del condado de Wayne.',
			cta: {
				label: 'Ver plataforma',
				title:
					'Haga clic aquí para consultar las últimas tendencias con respecto a el COVID-19',
			},
		},
		footer: {
			thanks: 'Gracias por ayudarnos a mantenerlo a salvo. ',
			space: {
				label: 'MANTENGA LA DISTANCIA',
				icon: { alt: 'Icono de dos personas de pie apartadas entre sí' },
			},
			mask: {
				label: 'UTILICE CUBREBOCA',
				icon: { alt: 'Icono de mascarilla' },
			},
			wash: {
				label: 'LÁVESE LAS MANOS',
				icon: { alt: 'Icono de lavado de manos' },
			},
			external: {
				gov: {
					label: 'Gobernar con transparencia',
					title: 'Transparencia en el gobierno',
				},
				news: {
					label: 'Eventos y novedades',
					title: 'Noticias y eventos del condado de Wayne',
				},
				info: {
					label: 'Información',
					title: 'Acerca del condado de Wayne, Michigan',
				},
			},
			social: {
				header: 'MANTÉNGASE CONECTADO',
			},
			copyright:
				'© 2021 Condado de Wayne, Míchigan. Todos los derechos reservados. <a title="Vea la declaración de privacidad del condado de Wayne, Michigan" href="https://www.waynecounty.com/county/privacy.aspx">Declaración de privacidad</a>, <a title="Vea los avisos legales y los términos de uso del condado de Wayne, Michigan" href="https://www.waynecounty.com/county/privacy.aspx">avisos legales y condiciones de uso.</a>',
		},
	},
	bn: {
		page: {
			title: 'ওয়েইন কাউন্টি, মিশিগান, COVID-19 সংস্থানসমূহ',
			description:
				'COVID-19 সম্পর্কিত মূল্যবান তথ্য পান, বিনামূল্যে টেস্ট করানোর কেন্দ্রগুলি দেখুন এবং পরিচর্যা, নিরাপত্তা এবং সংযোগ চিহ্ন অনুসন্ধান সম্পর্কে জানুন',
		},
		icons: {
			phone: { alt: 'চ্যাট বাবল যুক্ত ফোন আইকন ' },
			brand: {
				alt: 'চার্টার কাউন্টি অফ ওয়েইন মিশিগান লোগো',
				title: 'ওয়েইন কাউন্টি স্বাস্থ্য বিভাগ',
			},
			langAR: { alt: 'আপনার ভাষা আরবীতে পরিবর্তন করতে এখানে ক্লিক করুন' },
			langBN: { alt: 'আপনার ভাষা বাংলাতে পরিবর্তন করতে এখানে ক্লিক করুন' },
			langEN: { alt: 'আপনার ভাষা ইংরেজিতে পরিবর্তন করতে এখানে ক্লিক করুন' },
			langES: {
				alt: 'আপনার ভাষা স্প্যানিশে পরিবর্তন করার জন্য এখানে ক্লিক করুন',
			},
			facebook: {
				title: 'Facebook এ আমাদের অনুসরণ করুন',
				icon: { alt: 'Facebook লোগো' },
			},
			twitter: {
				title: 'Twitter এ আমাদের অনুসরণ করুন',
				icon: { alt: 'Twitter লোগো' },
			},
			chat: {
				title: 'অয়েইন কাউন্টি যোগাযোগের তথ্য',
				icon: { alt: 'চ্যাট বাবল' },
			},
		},
		hero: {
			head: 'আজই আপনার COVID-19 টিকাকরণের অ্যাপয়েন্টমেন্টের দিন স্থির করুন।',
			copy:
				'12+ সমস্ত ওয়েইন কাউন্টি অধিবাসী টিকা নিতে পারবেন। টিকাগুলি নিরাপদ, কার্যকর এবং বিনামূল্যের। <a href="https://www.waynecounty.com/covid19/vaccination.aspx">আরও&nbsp;জানুন</a>',
			image: {
				alt: 'ভ্যাকসিন CTA নম্বর সহ ব্যান্ডিড ছবি',
				title: 'আরো তথ্যের জন্য ফোন করুন',
			},
		},
		infobar: {
			trends: {
				label: 'COVID-19 এর ট্রেন্ডগুলি',
				title: 'সাম্প্রতিক COVID-19 ট্রেন্ডগুলি দেখতে এখানে ক্লিক করুন',
			},
			prevention: {
				label: 'প্রতিরোধের উপায়',
				title:
					'COVID-19 এর বিস্তার রোধ করতে আপনি কোন পদক্ষেপগুলি নিতে পারেন তা দেখতে এখানে ক্লিক করুন',
			},
			questions: 'COVID-19 প্রশ্নাবলী?',
			call:
				"আরো তথ্যের জন্য অনুগ্রহ করে 2-1-1 বা <a title='আরো তথ্যের জন্য ফোন করুন' href='tel:17342877870'>(734) 287-7870</a> নম্বরে কল করুন।",
		},
		freeTestingLockup: 'বিনামুল্যে টেষ্ট করানো যাবে',
		freeTests: {
			header: 'ড্রাইভ থ্রোতে বিনামুল্যে টেষ্ট করানো যাবে',
			body:
				'সাপ্তাহিক ছুটির দিনে আপনার কাছাকাছি এলাকাতে বিনামুল্যে টেষ্ট করানোর স্থানগুলো খুজুন।',
			cta: {
				label: 'বিনামূল্যের টেস্টগুলি',
				title:
					'COVID-19 টেস্টগুলি করাতে বিনামূল্যে আপনাকে ড্রাইভ থ্রো সম্পর্কে জানতে এখানে ক্লিক করুন',
			},
		},
		testingLocator: {
			header: 'টেস্টিং সাইটের অবস্থান নির্ণায়ক',
			body: 'আপনার নিকটবর্তী সমস্ত টেস্টিং সাইট দেখুন।',
			cta: {
				label: 'সমস্ত টেস্ট',
				title: 'আপনার নিকটবর্তী সমস্ত টেস্টিং সাইট দেখতে এখানে ক্লিক করুন',
			},
		},
		itspreads: {
			header: 'COVID-19 সহজেই ছড়িয়ে পড়ে।',
			subheader: 'যে কারোর এটি হতে পারে।',
			body:
				'<p>যে কারোর থেকে আপনি COVID-19 এর সংস্পর্শে আসতে পারেন—বন্ধু, আত্মীয়স্বজন, সহকর্মী বা অজানা লোকজন। আপনার যদি মনে হয় যে আপনি সংস্পর্শে এসে থাকতে পারেন বা অসুস্থ বোধ করেন, নিজেকে এবং অন্যান্যদের রক্ষা করতে আপনাকে কী করতে হবে তা খুঁজে দেখুন।</p>',
			cta: {
				label: 'চিকিৎসা, পরিচর্যা এবং নিরাপত্তার তথ্য দেখুন',
				title:
					'চিকিৎসার বিকল্প এবং নিরাপত্তা তথ্য সম্পর্কে জানতে এখানে ক্লিক করুন',
			},
			image: { alt: 'মাস্ক পরিহিত বিভিন্ন মানুষ' },
		},
		protect: {
			header:
				'আপনার বন্ধু, পরিবারের সদস্য এবং প্রতিবেশীদের সুরক্ষিত রাখতে সয়াহতা করুন',
			body:
				'একজন ওয়েইন কাউন্টি সংযোগ চিহ্ন অনুসন্ধানকারী (Wayne County Contact Tracer) আপনার সাথে যোগাযোগ করলে, অনুগ্রহ করে তাদের কলের উত্তর দিন। আপনার উত্তরগুলি অন্যদের নিরাপদ রাখতে সহায়তা করবে এবং আপনার পরিচয় সবসময় গোপন রাখা হবে।',
			cta: {
				label: 'সংযোগ চিহ্ন অনুসন্ধান সম্পর্কে জানুন',
				title: 'সংযোগ চিহ্ন অনুসন্ধান সম্পর্কে জানতে এখানে ক্লিক করুন',
			},
		},
		learn: {
			header: 'সংক্রমণটি কীভাবে থামাবেন তা জানুন',
			image: { alt: 'মুসলিম মা এবং মেয়ে ট্যাবলেট দেখছেন' },
		},
		takesteps:
			'একে অপরকে সুরক্ষিত করতে আমরা সকলে পদক্ষেপ নিতে পারি। একটি মাস্ক পরুন, অন্যান্য লোকজনের থেকে একটি নিরাপদ দূরত্বে থাকুন এবং বড় জনসমাগম এড়িয়ে চলুন। এটি আপনার বাড়িতে না থাকা আত্মীয়স্বজন এবং বন্ধু বান্ধবদের অন্তর্ভুক্ত করে।',
		holiday: {
			header: 'ছুটির দিনের নিরাপত্তা',
			body: 'আশু উৎসবের মরশুমে, সাম্প্রতিক নিরাপত্তা নির্দেশিকা দেখুন।',
			cta: {
				label: 'আরো জানুন',
				title:
					'কীভাবে নিরাপদে ছুটির দিনগুলি উদযাপন করবেন সে সম্পর্কে আরো জানতে এখানে ক্লিক করুন',
			},
		},
		stayinformed: {
			header: 'অবহিত থাকুন',
			body:
				'আপনার কমিউনিটির একজন রক্ষাকারী হয়ে উঠুন। COVID-19 সম্পর্কে ওয়েইন কাউন্টি স্বাস্থ্য বিভাগের সাম্প্রতিক তথ্য সম্পর্কে অবহিত থাকুন।',
			cta: {
				label: 'ড্যাশবোর্ড দেখুন',
				title: 'COVID-19 সম্পর্কে সাম্প্রতিক তথ্য পেতে এখানে ক্লিক করুন',
			},
		},
		footer: {
			thanks: 'আপনাকে নিরাপদ রাখতে সহায়তা করার জন্য ধন্যবাদ!',
			space: {
				label: 'দূরত্ব বজায় রাখুন',
				icon: { alt: 'দুজন লোকের দূরত্ব বজায় রেখে দাঁড়িয়ে থাকার আইকন' },
			},
			mask: { label: 'মাস্ক পড়ুন', icon: { alt: 'মাস্কের আইকন' } },
			wash: { label: 'হাত ধুন', icon: { alt: 'হাত ধোওয়ার আইকন' } },
			external: {
				gov: { label: 'প্রশাসনের মধ্যে স্বচ্ছতা', title: 'প্রশাসনে স্বচ্ছতা' },
				news: {
					label: 'সংবাদ এবং ইভেন্টসমূহ',
					title: 'ওয়েইন কাউন্টি সংবাদ এবং ইভেন্টসমূহ',
				},
				info: { label: 'তথ্য', title: 'ওয়েইন কাউন্টি, মিশিগান সম্পর্কে' },
			},
			social: {
				header: 'সংযুক্ত থাকুন',
			},
			copyright:
				'2021 ওয়েইন কাউন্টি, মিশিগান। সর্বস্বত্ব সংরক্ষিত। <a title="ওয়েইন কাউন্টি, মিশিগান, গোপনীয়তা বিবৃতি দেখুন" href="https://www.waynecounty.com/county/privacy.aspx">গোপনীয়তা বিবৃতি</a>, <a title="ওয়েইন কাউন্টি, মিশিগান, আইনি বিজ্ঞপ্তি এবং ব্যবহারের শর্তাবলী দেখুন" href="https://www.waynecounty.com/county/privacy.aspx">আইনী বিজ্ঞপ্তি এবং ব্যবহারের শর্তাদি।</a>',
		},
	},
	ar: {
		page: {
			title: 'مقاطعة واين، ميشيغان، موارد كوفيد-19',
			description:
				'احصل على معلومات قيّمة حول كوفيد-19، اعثر على مواقع تُجرى فيها الفحوصات مجاناً وتعرف على طرق الرعاية والسلامة وتعقب المخالطرين',
		},
		icons: {
			chat: {
				title: 'معلومات الاتصال بمقاطعة واين',
				icon: { alt: 'فقاعة دردشة' },
			},
			phone: { alt: 'أيقونة هاتف مع فقاعات دردشة' },
			brand: {
				alt: 'شعار Charter County of Wayne Michigan',
				title: 'إدارة الصحة في مقاطعة واين',
			},
			langAR: { alt: 'انقر هنا لتغيير لغتك إلى العربية' },
			langBN: { alt: 'انقر هنا لتغيير لغتك إلى البنغالية' },
			langEN: { alt: 'انقر هنا لتغيير لغتك إلى الإنكليزية' },
			langES: { alt: 'اضغط هنا لتغيير اللغة الى الاسبانية' },
			facebook: {
				title: 'تابعنا على فيسبوك',
				icon: { alt: 'شعار فيسبوك' },
			},
			twitter: { title: 'تابعنا على تويتر', icon: { alt: 'شعار تويتر' } },
		},
		hero: {
			head: 'احجز موعد لتلقي لقاح كوفيد١٩',
			copy:
				'جميع سكان مقاطعة واين بعمر ١٢ سنة وما فوق مؤهلين للحصول على اللقاح اللقاح آمن وفعال ومجاني <a href="https://www.waynecounty.com/covid19/vaccination.aspx	">للمزيد من المعلومات</a>',
			image: {
				alt: 'صورة لضماد طبي مع رقم اللقاح التسلسلي ',
				title: 'اتصل للمزيد من المعلومات',
			},
		},
		infobar: {
			trends: {
				label: '19-اتجاهات كوفيد',
				title: 'انقر هنا للتحقق من أحدث اتجاهات كوفيد-19',
			},
			prevention: {
				label: 'الوقاية',
				title:
					'انقر هنا لمعرفة الخطوات التي يمكنك اتخاذها لوقف انتشار كوفيد-19',
			},
			questions: 'هل لديك أسئلة حول كوفيد-19؟ ',
			call:
				"هل لديك أسئلة حول كوفيد-19؟ يُرجى الاتصال على 2-1-1 أو <a title='للمزيد من المعلومات.' href='tel:17342877870'>(734) 287-7870</a>",
		},
		freeTestingLockup: 'الفحص المجاني متوفر',
		freeTests: {
			header: 'الفحوصات المجانية من دون مغادرة السيارة.',
			body:
				'اعثر على مواقع الفحوصات المجانية خلال عطلة نهاية الأسبوع القريبة منك.',
			cta: {
				label: 'فحوصات مجانية',
				title:
					'انقر هنا لمعرفة المزيد عن فحوصات كوفيد-19 المجانية التي يمكنك إجراؤها من دون مغادرة السيارة',
			},
		},
		testingLocator: {
			header: 'محدد مواقع الفحوصات.',
			body: 'شاهد جميع مواقع الفحوصات القريبة منك.',
			cta: {
				label: 'جميع الفحوصات',
				title: 'انقر هنا لرؤية جميع مواقع إجراء الفحوصات القريبة منك',
			},
		},
		itspreads: {
			header: 'ينتشر كوفيد-19 بسهولة.',
			subheader: 'الجميع معرضون للإصابة.',
			body:
				'<p>قد ينتقل إليك كوفيد-19 من أيّ شخص – صديق، قريب، زميل عمل أو غريب. إن كنت تعتقد بأنك قد تعرضت للعدوى أو تشعر بالمرض، اعرف ما الذي تحتاج إليه لحماية نفسك والآخرين.</p>',
			cta: {
				label: 'راجع معلومات العلاج والرعاية والسلامة',
				title: 'انقر هنا للاطلاع على معلومات حول خيارات العلاج والسلامة',
			},
			image: { alt: 'أشخاص متنوعون يرتدون أقنعة' },
		},
		protect: {
			header: 'ساعد في حماية أصدقائك وعائلتك وجيرانك',
			body:
				'إذا اتصل بك مسؤول الصحة العامة في مقاطعة واين، فيُرجى الرد على المكالمة. تساعد إجاباتك في الحفاظ على سلامة الآخرين وستكون معلوماتك وهويتك محميّة دائماً. نحن نحترم خصوصيتك.',
			cta: {
				label: 'معلومات حول إجراء تقصي العدوى',
				title: 'انقر هنا للاطلاع على المزيد من المعلومات حول تقصي الحالات',
			},
		},
		learn: {
			header: '	تعلم كيف تحدّ من انتشار العدوى',
			image: { alt: 'أمّ مسلمة وابنتها تنظران إلى جهاز لوحي' },
		},
		takesteps:
			'يمكننا جميعاً اتخاذ خطوات لحماية بعضنا البعض. ارتدِ قناعاً، وحافظ على مسافة آمنة من الآخرين وتجنّب التجمعات الكبيرة. يشمل ذلك الأنشطة مع العائلة والأصدقاء الذين لا يقيمون في منزلك.',
		holiday: {
			header: 'السلامة خلال العطل',
			body: 'مع اقتراب المناسبات العديدة، راجع إرشادات السلامة.',
			cta: {
				label: 'للمزيد من المعلومات',
				title:
					'انقر هنا لمعرفة المزيد حول كيفية الحفاظ على السلامة أثناء عطلات الأعياد',
			},
		},
		stayinformed: {
			header: 'إبقَ على اطلاع',
			body:
				'ساهم في حماية مجتمعك. تابع أحدث المعلومات حول كوفيد-19 من إدارة الصحة في مقاطعة واين.',
			cta: {
				label: 'راجع لوحة التحكم',
				title: 'انقر هنا للاطلاع على أحدث المعلومات حول كوفيد-19',
			},
		},
		footer: {
			thanks: 'شكراً لمساعدتنا في الحفاظ على سلامتك!',
			space: {
				label: 'حافظ على التباعد  ',
				icon: { alt: 'أيقونة لشخصين يقفان متباعدين' },
			},
			mask: { label: 'إرتدِ القناع  ', icon: { alt: 'أيقونة القناع' } },
			wash: { label: 'أغسل يديك', icon: { alt: 'أيقونة غسيل اليدين' } },
			external: {
				gov: {
					label: 'الشفافية في الحكومة',
					title: 'الشفافية في الحُكم',
				},
				news: { label: 'أخبار وأحداث', title: 'مقاطعة واين أخبار وأحداث' },
				info: { label: 'معلومات', title: 'حول مقاطعة واين، ميشيغان' },
			},
			social: {
				header: 'ابق على تواصل',
			},
			copyright:
				'2021 مقاطعة واين، ميشيغان. كل الحقوق محفوظة. <a title="عرض مقاطعة واين، ميشيغان، بيان الخصوصية" href="https://www.waynecounty.com/county/privacy.aspx"> بيان الخصوصية و </a>, <a title="عرض مقاطعة واين، ميشيغان، الإشعارات القانونية وشروط الاستخدام" href="https://www.waynecounty.com/county/privacy.aspx">الإشعارات القانونية وشروط الاستخدام.',
		},
	},
};

export default LanguageModel;
