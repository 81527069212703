/**
 * Chrome's built in breakpoints
 */
const ChromeBreakpoints = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
};

/**
 * Breakpoints object.
 * The idea here is that we could set multiple breakpoints
 * to Chrome's if necessary.
 *
 * If you built in mobileS, and decided to change that, it would be
 * very easy to set this one to Chrome's mobileL.
 */
const Breakpoints = {
	mobileS: ChromeBreakpoints.mobileL,
	mobileM: ChromeBreakpoints.mobileL,
	mobileL: ChromeBreakpoints.mobileL,
	custom560: '560px',
	custom700: '700px',
	tablet: ChromeBreakpoints.tablet,
	laptop: ChromeBreakpoints.laptop,
	laptopL: ChromeBreakpoints.laptopL,
};

export default Breakpoints;
