import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import LanguageContext from '../../_utilities/LanguageContext';
import BG from './hero-bg.jpg';
import Bandaid from './bandaid-with-number.png';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled.div`
	position: relative;
	background-color: #70a0b7;
	background: url(${BG}) no-repeat;
	background-size: cover;

	section {
		box-sizing: border-box;
		z-index: 5;
		color: #ffffff;
		padding-left: 4%;
		padding-right: 4%;
		bottom: 0;
		padding-bottom: 2%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		min-height: 404px;
	}

	h1 {
		font-weight: 400;
		font-size: 47px;
		line-height: 56px;
		text-transform: uppercase;
		font-family: korolev, sans-serif;
		font-weight: 900;
		color: #bfdffb;
		margin-bottom: 0px;
		text-align: center;
		span {
			color: #1a25ab;
		}
	}

	a.call {
		width: 53%;
		min-width: 280px;
		img {
			width: 100%;
			height: auto;
		}
	}

	p {
		margin: 0;
		font-size: 23px;
		line-height: 28px;
		right: 0;
		bottom: 0;
		color: #c5dff8;
		font-family: korolev, sans-serif;
		font-weight: 700;
		text-align: center;
		a {
			color: inherit;
		}
	}

	@media (max-width: ${Breakpoints.laptop}) {
		h1 {
			font-size: 41px;
		}
		p {
			font-size: 20px;
		}
	}

	@media (max-width: ${Breakpoints.custom560}) {
		section {
			min-height: 311px;
		}
		h1 {
			line-height: 41px;
			span {
				display: block;
			}
		}
		p {
			font-size: 16px;
			line-height: 1.2;
			letter-spacing: 0.8px;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		h1 {
			font-size: 35px;
			line-height: 35px;
			span {
				display: block;
			}
		}
		p {
			font-size: 14px;
		}

		section {
			left: 3%;
			right: 3%;
			padding-bottom: 16px;
		}

		.call {
			img {
				width: 100%;
				height: auto;
			}
		}

		figure {
			height: 237px;
			img {
				height: 100%;
				width: auto;
				position: absolute;
				right: -15px;
				top: 0;
			}
		}
	}
`;

function Hero() {
	return (
		<LanguageContext.Consumer>
			{({ getText, lang }) => {
				let { head, copy, image } = getText('hero');
				return (
					<Container>
						<section>
							<h1>{ReactHtmlParser(head)}</h1>
							<a className='call' href='tel:18666103885' title={image.title}>
								<img src={Bandaid} alt={image.alt} />
							</a>
							<p>{ReactHtmlParser(copy)}</p>
						</section>
					</Container>
				);
			}}
		</LanguageContext.Consumer>
	);
}

export default Hero;
