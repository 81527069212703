import React from 'react';
import styled from 'styled-components';
import LanguageContext from '../../_utilities/LanguageContext';
import ContentBar from '../../_atoms/ContentBar';
import Icon from '../../_atoms/Icon';
import Breakpoints from '../../_utilities/Breakpoints';

const LanguageButton = styled.button`
	color: #1f2a40;
	height: 34px;
	font-family: 'Lato', sans-serif;
	font-size: 21px;
	text-transform: uppercase;
	border: none;
	background: none;
	cursor: pointer;
	font-weight: 900;
	letter-spacing: 1.2px;
	display: flex;
	align-items: center;

	img {
		pointer-events: none;
		height: 100%;
	}

	&#lang-en img {
		height: 65%;
	}
	&#lang-es img {
		height: 75%;
	}

	@media (max-width: ${Breakpoints.custom560}) {
		height: 24px;
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		height: 18px;
	}
`;

/**
 * Language toggle button
 * @param {object} props
 */
function LanguageToggleButton(props) {
	return (
		<LanguageButton
			id={`lang-${props.lang}`}
			onClick={props.onClick}
			data-lang={props.lang}
		>
			<Icon type={`lang${props.lang.toUpperCase()}`} />
		</LanguageButton>
	);
}

/**
 * language switcher component
 */
function LanguageSwitcher() {
	return (
		<LanguageContext.Consumer>
			{({ toggleLanguage }) => (
				<ContentBar bgColor='#C5DFF8' justify='space-around'>
					<LanguageToggleButton onClick={toggleLanguage} lang='ar' />
					<LanguageToggleButton onClick={toggleLanguage} lang='bn' />
					<LanguageToggleButton onClick={toggleLanguage} lang='es' />
					<LanguageToggleButton onClick={toggleLanguage} lang='en' />
				</ContentBar>
			)}
		</LanguageContext.Consumer>
	);
}

export default LanguageSwitcher;
