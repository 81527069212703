import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled.a`
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	padding: 4px 18px;
	min-width: 142px;
	background-color: #c5dff8;
	color: #1f2a40;
	text-decoration: none;
	font-weight: 700;
	border-radius: 2px;
	box-sizing: border-box;
	display: inline-block;
	text-align: center;

	&:hover {
		color: #ffffff;
		background-color: #1f2a40;
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		font-size: 13.9px;
		letter-spacing: 0.8px;
	}
`;

function CTAButton(props) {
	return (
		<Container href={props.uri} title={props.title}>
			{props.label}
		</Container>
	);
}

CTAButton.propTypes = {
	/**
	 * The button label
	 */
	label: PropTypes.string.isRequired,
	/**
	 * The uri for the button
	 */
	uri: PropTypes.string.isRequired,
	// title attrbute
	title: PropTypes.string,
};

export default CTAButton;
