import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CTAButton from '../../_atoms/CTAButton';
import LanguageContext from '../../_utilities/LanguageContext';
import ReactHtmlParser from 'react-html-parser';
import Breakpoints from '../../_utilities/Breakpoints';

const Container = styled.section`
	direction: ${(props) => props.direction};

	h1 {
		text-transform: uppercase;
		font-size: ${(props) => (props.size === 'sm' ? '16px' : '21px')};
		color: #1f2a40;
		font-weight: 900;
		margin: 0 0 2px 0;
		margin-bottom: ${(props) => (props.size === 'sm' ? '2px' : '23px')};
		letter-spacing: ${(props) => (props.size === 'sm' ? '0.9px' : '1.2px')};
	}

	p {
		color: #474747;
		margin: 0 0 20px 0;
		margin-bottom: ${(props) => (props.size === 'sm' ? '20px' : '23px')};
		font-size: ${(props) => (props.size === 'sm' ? '16px' : '21px')};
		letter-spacing: ${(props) => (props.size === 'sm' ? '0.9px' : '1.2px')};
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		h1 {
			font-size: 15.53px;
			letter-spacing: 0.9px;
			line-height: 19.84px;
		}
		p {
			font-size: 13.5px;
			line-height: 19.84px;
			letter-spacing: 0.9px;
		}
		text-align: center;
		width: 100%;
		box-sizing: border-box;
		margin-bottom: 25px;
	}
`;

function CTABlock(props) {
	return (
		<LanguageContext.Consumer>
			{({ lang, getText }) => {
				const { header, body, cta } = getText(props.dataKey);

				let _direction = lang === 'ar' ? 'rtl' : 'ltr';

				return (
					<Container size={props.size} direction={_direction}>
						<h1>{header}</h1>
						<p>{ReactHtmlParser(body)}</p>
						<CTAButton uri={props.uri} label={cta.label} title={cta.title} />
					</Container>
				);
			}}
		</LanguageContext.Consumer>
	);
}

CTABlock.propTypes = {
	dataKey: PropTypes.string.isRequired,
	uri: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['sm', 'lg']),
};

CTABlock.defaultProps = {
	size: 'sm',
};

export default CTABlock;
