import React from 'react';
import PropTypes from 'prop-types';
import LanguageContext from '../../_utilities/LanguageContext';

const requestImageFile = require.context('./icons', true, /^\.\/.*\.svg$/);

function Icon(props) {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				const { alt } = getText('icons')[props.type];
				return <img src={requestImageFile(`./${props.type}.svg`)} alt={alt} />;
			}}
		</LanguageContext.Consumer>
	);
}

Icon.propTypes = {
	type: PropTypes.oneOf([
		'phone',
		'langAR',
		'langBN',
		'langEN',
		'langES',
		'brand',
		'facebook',
		'twitter',
		'chat',
	]),
};

export default Icon;
