import React from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';
import ReactHtmlParser from 'react-html-parser';
import Helmet from 'react-helmet';
import queryString from 'query-string';

import LanguageContext from './components/_utilities/LanguageContext';
import LanguageSwitcher from './components/_molecules/LanguageSwitcher';
import BrandBar from './components/_molecules/BrandBar';
import Hero from './components/_molecules/Hero';
import InfoBar from './components/_molecules/InfoBar';
import CTABlock from './components/_molecules/CTABlock';
import LanguageModel from './LanguageModel';
import Footer from './components/_organisms/Footer';
import CopyOverImage from './components/_molecules/CopyOverImage';

import Icon from './components/_atoms/Icon';
import CTAButton from './components/_atoms/CTAButton';
import CopyLockup from './components/_atoms/CopyLockup';
import Breakpoints from './components/_utilities/Breakpoints';

/**
 * Global style sheet
 * Normalization is done through the Normalize package
 */
const GlobalStyle = createGlobalStyle`
	body{
		font-family: 'Lato', sans-serif;
		font-size: 16px;
		line-height: 23px;
		color:#474747;
		font-weight: 400;
		/* background: red; */
	}
`;

const FreeTestingContainer = styled.div`
	box-sizing: border-box;
	padding: 60px 45px;
	display: flex;
	align-items: center;

	> *:not(:last-child) {
		margin-right: 30px;
	}

	@media (max-width: ${Breakpoints.laptop}) {
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 30px;
		> *:not(:last-child) {
			margin-right: 0;
		}

		h2 span {
			display: inline;
		}

		> :not(:first-child) {
			max-width: 45%;
		}

		> *:first-child {
			width: 100%;
			justify-content: center;
		}

		> :nth-child(2) {
			margin-right: 30px;
		}
	}

	@media (max-width: ${Breakpoints.custom700}) {
		h2 span {
			display: block;
		}
	}

	@media (max-width: 690px) {
		> :first-child {
			margin-bottom: 25px;
		}

		> :not(:first-child) {
			max-width: none;
		}

		h2 {
			text-align: center;
		}
		> :nth-child(2) {
			margin-right: 0;
		}
		> :nth-child(n + 2) {
			width: 100%;
			margin-bottom: 25px;
			text-align: center;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		padding: 30px 10px 5px;
		> *:not(:last-child) {
			margin-right: 0;
		}
	}
`;

const SpreadsEasilyContainer = styled.div`
	background-color: #f1f1f1;
	padding: 40px 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	figure {
		margin: 0;
	}

	@media (max-width: ${Breakpoints.laptop}) {
		flex-wrap: wrap;
		padding: 30px 10px;
		figure {
			width: 100%;
		}
		img {
			width: 100%;
			height: auto;
		}
		align-items: flex-start;
	}
`;

const SpreadsEasilySection = styled.section`
	padding: 0 60px 0 30px;
	box-sizing: border-box;
	header {
		color: #1f2a40;
	}

	h1 {
		margin: 0;
		font-size: 24px;
		letter-spacing: 1.4px;
		line-height: 40px;
		font-weight: 400;
		text-transform: uppercase;
	}

	h2 {
		margin: 0;
		font-size: 36px;
		line-height: 40px;
		letter-spacing: 2px;
		font-weight: 900;
		text-transform: uppercase;
	}

	@media (max-width: ${Breakpoints.laptop}) {
		display: grid;
		align-items: start;
		padding-top: 20px;
		row-gap: 9px;
		column-gap: 30px;
		header {
			grid-column: 1;
			grid-row: span 2;
			align-self: center;
		}
		p {
			grid-column: 2;
			grid-row: 1;
			margin: 0;
		}
		footer {
			grid-column: 2;
			grid-row: 2;
		}
	}

	@media (max-width: ${Breakpoints.tablet}) {
		padding-top: 10px;
		display: block;
		p {
			margin: 16px 0;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		padding: 0;
		text-align: center;
		margin-top: 24px;

		h1 {
			font-size: 22.23px;
			line-height: 27px;
			letter-spacing: 0.1px;
		}

		h2 {
			font-size: 22.23px;
			line-height: 27px;
			letter-spacing: 1.3px;
		}

		p {
			font-size: 13.8px;
			line-height: 18px;
		}
		a {
			font-size: 12.97px;
			letter-spacing: 0.2px;
			width: 100%;
		}
	}
`;

function SpreadsEasilyCTABlock(props) {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				const { header, subheader, body, cta } = getText('itspreads');
				return (
					<SpreadsEasilySection>
						<header>
							<h1>{header}</h1>
							<h2>{subheader}</h2>
						</header>
						{ReactHtmlParser(body)}
						<footer>
							<CTAButton
								label={cta.label}
								uri='https://www.waynecounty.com/covid19/resources-for-residents.aspx'
								title={cta.title}
							/>
						</footer>
					</SpreadsEasilySection>
				);
			}}
		</LanguageContext.Consumer>
	);
}

const HelpProtect = styled.div`
	padding: 60px 45px;
	display: flex;
	justify-content: center;
	> :first-child {
		margin-right: 30px;
	}
	> :last-child {
		max-width: 75%;
	}

	@media (max-width: ${Breakpoints.custom560}) {
		padding: 30px 10px;
		display: block;
		text-align: center;

		img {
			width: 118px;
			height: auto;
			margin: 0 auto 17px;
		}

		> :first-child {
			margin-right: 0;
		}
		> :last-child {
			max-width: 100%;
		}
	}
`;

const TakingSteps = styled.div`
	padding: 60px 45px;
	display: flex;
	justify-content: space-between;
	> * {
		flex: 0 0 30%;
	}
	> p {
		margin-top: 0;
	}
	@media (max-width: ${Breakpoints.custom700}) {
		flex-wrap: wrap;
		> * {
			flex: 0 0 44%;
		}
		> :first-child {
			flex: 0 0 100%;
		}
	}

	@media (max-width: ${Breakpoints.mobileS}) {
		display: block;
		padding: 30px 10px 10px;

		> p {
			font-size: 13.8px;
			line-height: 18px;
			text-align: center;
			margin-bottom: 30px;
			padding: 0 9px;
		}
	}
`;

function CopyBlock(props) {
	return (
		<LanguageContext.Consumer>
			{({ getText }) => {
				return <p>{ReactHtmlParser(getText(props.modelKey))}</p>;
			}}
		</LanguageContext.Consumer>
	);
}

//Landing page component
class Home extends React.Component {
	constructor(props) {
		super(props);

		const language = queryString.parse(window.location.search).lang || 'en';

		// bind the language handler callback
		this.languageSwitchHandler = this.languageSwitchHandler.bind(this);
		this.getText = this.getText.bind(this);

		this.state = {
			lang: language,
			toggleLanguage: this.languageSwitchHandler,
			getText: this.getText,
			model: LanguageModel,
		};
	}

	componentDidMount() {}

	/**
	 * will retrive the object for the key requested
	 * if the object doesn't exist, it will return the english
	 * version of the object
	 */
	getText(key) {
		let _data = this.state.model[this.state.lang];
		if (_data.hasOwnProperty(key)) return _data[key];
		else return this.state.model['en'][key];
	}

	/**
	 * handles switching of the language
	 * @param {event} e the event
	 */
	languageSwitchHandler(e) {
		let _lang = e.currentTarget.getAttribute('data-lang');

		window.history.replaceState(null, null, `?lang=${_lang}`);

		this.setState({ lang: _lang });
	}

	render() {
		// main site container
		const SiteContainer = styled.main`
			width: 100%;
			max-width: 1230px;
			margin: 0 auto;
		`;

		let { title, description } = this.getText('page');
		let { image } = this.getText('itspreads');

		return (
			<SiteContainer>
				<Helmet>
					<title>{title}</title>
					<meta name='description' content={description} />
				</Helmet>
				<LanguageContext.Provider value={this.state}>
					<header>
						<BrandBar />
						<LanguageSwitcher />
						<Hero />
						<InfoBar />
					</header>
					<FreeTestingContainer>
						<CopyLockup modelkey='freeTestingLockup' />
						<CTABlock
							dataKey='freeTests'
							uri='https://www.waynecounty.com/covid19/testing.aspx'
						/>
						<CTABlock
							dataKey='testingLocator'
							uri='https://www.michigan.gov/coronavirus/0,9753,7-406-99891_99912-531745--,00.html'
						/>
					</FreeTestingContainer>
					<SpreadsEasilyContainer>
						<figure>
							<img
								src={process.env.PUBLIC_URL + '/img/fpo.jpg'}
								alt={image.alt}
							/>
						</figure>
						<SpreadsEasilyCTABlock />
					</SpreadsEasilyContainer>
					<HelpProtect>
						<Icon type='phone' />
						<CTABlock
							size='lg'
							dataKey='protect'
							uri='https://www.michigan.gov/coronavirus/0,9753,7-406-99891_99913---,00.html'
						/>
					</HelpProtect>
					<CopyOverImage image='stop-the-spread.png' modelkey='learn' />
					<TakingSteps>
						<CopyBlock modelKey='takesteps' />
						<CTABlock
							dataKey='holiday'
							uri='https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/holidays.html'
						/>
						<CTABlock
							dataKey='stayinformed'
							uri='https://wayne.maps.arcgis.com/apps/opsdashboard/index.html#/d04fff4645b140319fad972eb3740550'
						/>
					</TakingSteps>
					<Footer />
				</LanguageContext.Provider>
			</SiteContainer>
		);
	}
}

export default function App() {
	return (
		<React.Fragment>
			<Normalize />
			<GlobalStyle />
			<Home />
		</React.Fragment>
	);
}

// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));
